<template>
	<div class="wallpaper-info">
		<div class="my-portfolio">
			<div class="portfolio-cards">
				<div v-for="item in imglist" :key="item.index" class="portfolio-card">
					<div class="portfolio-img">
						<img class="portfolio-img" v-lazy="item.img" :alt="item.info">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return{
			imglist:[
				{index:1,img:'http://p1.music.126.net/wGbO39sAMI9lroiLYXJsDQ==/109951167760004737.jpg?param=120y120',info:'启示录'},
				{index:2,img:'http://p2.music.126.net/KTo5oSxH3CPA5PBTeFKDyA==/109951164581432409.jpg?param=120y120',info:'摩天动物园'},
				{index:3,img:'http://p2.music.126.net/fI5OPx5-0G4g384rGNQftA==/109951163751293472.jpg?param=120y120',info:'睡皇后'},
				{index:4,img:'http://p2.music.126.net/X7f92tSJ-b0_sC1u9tgAyQ==/109951163654227442.jpg?param=120y120',info:'毒苹果'},
				{index:5,img:'http://p2.music.126.net/tXCIFsVDK6IKcQ9YWxwOEg==/109951163523944497.jpg?param=120y120',info:'另一个童话'},
				{index:6,img:'http://p1.music.126.net/eX7aCeGTF3Lt_swJSbE4yQ==/18700493765616158.jpg?param=120y120',info:'25 LOOKS'},
				{index:7,img:'http://p2.music.126.net/kVwk6b8Qdya8oDyGDcyAVA==/1364493930777368.jpg?param=120y120',info:'新的心跳'},
				{index:8,img:'http://p2.music.126.net/w0EbVeyPeMZYQsaHV7PaAA==/109951163789161236.jpg?param=120y120',info:'The Best of G.E.M.'},
				{index:9,img:'http://p2.music.126.net/3XEICRFzQPrHPUMD0xm1Jw==/109951163069323980.jpg?param=120y120',info:'Xposed'},
				{index:10,img:'http://p1.music.126.net/Q6ROCCNP2qtqmDzp4uswgA==/109951163069327659.jpg?param=120y120',info:'My Secret'},
				{index:11,img:'http://p1.music.126.net/ZOspX8Q2sEjcF_wTHSWaqQ==/5853799906405628.jpg?param=120y120',info:'18'},
				{index:12,img:'http://p1.music.126.net/SRSzJNkVegFlCJVc49VvxQ==/109951163069324876.jpg?param=120y120',info:'G.E.M.'},
				{index:1,img:'http://p1.music.126.net/wGbO39sAMI9lroiLYXJsDQ==/109951167760004737.jpg?param=120y120',info:'启示录'},
				{index:2,img:'http://p2.music.126.net/KTo5oSxH3CPA5PBTeFKDyA==/109951164581432409.jpg?param=120y120',info:'摩天动物园'},
				{index:3,img:'http://p2.music.126.net/fI5OPx5-0G4g384rGNQftA==/109951163751293472.jpg?param=120y120',info:'睡皇后'},
				{index:4,img:'http://p2.music.126.net/X7f92tSJ-b0_sC1u9tgAyQ==/109951163654227442.jpg?param=120y120',info:'毒苹果'},
				{index:5,img:'http://p2.music.126.net/tXCIFsVDK6IKcQ9YWxwOEg==/109951163523944497.jpg?param=120y120',info:'另一个童话'},
				{index:6,img:'http://p1.music.126.net/eX7aCeGTF3Lt_swJSbE4yQ==/18700493765616158.jpg?param=120y120',info:'25 LOOKS'},
				{index:7,img:'http://p2.music.126.net/kVwk6b8Qdya8oDyGDcyAVA==/1364493930777368.jpg?param=120y120',info:'新的心跳'},
				{index:8,img:'http://p2.music.126.net/w0EbVeyPeMZYQsaHV7PaAA==/109951163789161236.jpg?param=120y120',info:'The Best of G.E.M.'},
				{index:9,img:'http://p2.music.126.net/3XEICRFzQPrHPUMD0xm1Jw==/109951163069323980.jpg?param=120y120',info:'Xposed'},
				{index:10,img:'http://p1.music.126.net/Q6ROCCNP2qtqmDzp4uswgA==/109951163069327659.jpg?param=120y120',info:'My Secret'},
				{index:11,img:'http://p1.music.126.net/ZOspX8Q2sEjcF_wTHSWaqQ==/5853799906405628.jpg?param=120y120',info:'18'},
				{index:12,img:'http://p1.music.126.net/SRSzJNkVegFlCJVc49VvxQ==/109951163069324876.jpg?param=120y120',info:'G.E.M.'},
				{index:1,img:'http://p1.music.126.net/wGbO39sAMI9lroiLYXJsDQ==/109951167760004737.jpg?param=120y120',info:'启示录'},
				{index:2,img:'http://p2.music.126.net/KTo5oSxH3CPA5PBTeFKDyA==/109951164581432409.jpg?param=120y120',info:'摩天动物园'},
				{index:3,img:'http://p2.music.126.net/fI5OPx5-0G4g384rGNQftA==/109951163751293472.jpg?param=120y120',info:'睡皇后'},
				{index:4,img:'http://p2.music.126.net/X7f92tSJ-b0_sC1u9tgAyQ==/109951163654227442.jpg?param=120y120',info:'毒苹果'},
				{index:5,img:'http://p2.music.126.net/tXCIFsVDK6IKcQ9YWxwOEg==/109951163523944497.jpg?param=120y120',info:'另一个童话'},
				{index:6,img:'http://p1.music.126.net/eX7aCeGTF3Lt_swJSbE4yQ==/18700493765616158.jpg?param=120y120',info:'25 LOOKS'},
				{index:7,img:'http://p2.music.126.net/kVwk6b8Qdya8oDyGDcyAVA==/1364493930777368.jpg?param=120y120',info:'新的心跳'},
				{index:8,img:'http://p2.music.126.net/w0EbVeyPeMZYQsaHV7PaAA==/109951163789161236.jpg?param=120y120',info:'The Best of G.E.M.'},
				{index:9,img:'http://p2.music.126.net/3XEICRFzQPrHPUMD0xm1Jw==/109951163069323980.jpg?param=120y120',info:'Xposed'},
				{index:10,img:'http://p1.music.126.net/Q6ROCCNP2qtqmDzp4uswgA==/109951163069327659.jpg?param=120y120',info:'My Secret'},
				{index:11,img:'http://p1.music.126.net/ZOspX8Q2sEjcF_wTHSWaqQ==/5853799906405628.jpg?param=120y120',info:'18'},
				{index:12,img:'http://p1.music.126.net/SRSzJNkVegFlCJVc49VvxQ==/109951163069324876.jpg?param=120y120',info:'G.E.M.'},
			],
		}
	}
}
</script>

<style scoped lang="scss">

.wallpaper-info{
	width: 100%;
	overflow: auto;
	align-items: center;
	background-color: rgba(16 18 27 / 10%);
	padding: 20px 40px;
	color: #ffffff;	

	.my-portfolio{
		width: 100%;
		overflow: hidden;
		
		.portfolio-cards{
			display: flex;
			flex-wrap: wrap;
			margin-right: -10px;
			
			.portfolio-card{
				width: calc(33.333% - 10px);
				margin-right: 10px;
				margin-top: 10px;
				box-shadow: 0 2px 5px #888888;
				border-radius: 10px;
				background-color: white;
				
				.portfolio-img{
					width: 100%;
					height: 100%;
				}
				
				h4 {
					background: #fff;
					color: #000;
					text-align: center;
					margin: 0;
					padding: 4px 0 10px;
					border-radius: 0 0 10px 10px;
				}
				
				@media screen and (max-width: 1110px) {
					width: calc(50% - 10px);
				}
				@media screen and (max-width: 565px) {
					width: calc(100% - 10px);
				}
			}
			.portfolio-card:hover{
				cursor: pointer;
				transform: scale(1.02);
				border-radius: 10px;
				background-color: rgba(16 18 27 / 20%);
			}
			
		}
	}
	
}

</style>
