export default {
    development: {
      baseUrl: 'http://root.gudugudugu.com/' // 调试接口域名
    },
    test: {
      baseUrl: 'http://root.gudugudugu.com/' // 测试接口域名
    },
    production: {
      baseUrl: 'http://root.gudugudugu.com/' // 正式接口域名
    }
  }