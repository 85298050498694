import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import lazyPlugin from 'vue3-lazy'
import axios from './util/axios'
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'element-plus/dist/index.css'
import { ElMessage, ElNotification, ElLoading} from 'element-plus'

const app = createApp(App)
app.use(lazyPlugin, {
  loading: 'image/lazy.svg',
  error: 'image/lazy.svg'
})
app.use(ElLoading);
app.config.globalProperties.$message = ElMessage;
app.config.globalProperties.$notification = ElNotification;
app.config.globalProperties.$axios = axios
app.use(router).mount('#app')
