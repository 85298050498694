import { createRouter, createWebHashHistory } from 'vue-router'

// main page
import TreasureView from "@/views/TreasureView";
import GamesView from "@/views/GamesView";
import WallPaperView from "@/views/WallPaperView";
import NovelView from "@/views/NovelView";
import AboutMeView from "@/views/AboutMeView";
// all page
import GalleryView from "@/views/treasure/GalleryView";
import ArticleDetailsView from "@/views/gadget/ArticleDetailsView";
import ColorClipboardView from "@/views/treasure/ColorClipboardView";
import WaterMarkView from "@/views/treasure/WaterMarkView";
import DigitalClockView from "@/views/treasure/DigitalClockView";
import PracticalTableView from "@/views/useless/PracticalTableView";
import PersonInfoView from "@/views/gadget/PersonInfoView";
import GreenEyesCatView from "@/views/wallpaper/GreenEyesCatView";
import TictactoeView from "@/views/games/TictactoeView";


const routes = [
  {
    path: '/',
    name:'index',
    redirect:'/treasure',
  },

  //  百宝箱
  {
    path: '/treasure',
    name: 'treasure',
    component: TreasureView,
    children:[
      {
        path: 'gallery',
        name: 'gallery',
        component: GalleryView,
      },
      {
        path: 'digitalClock',
        name: 'digitalClock',
        component: DigitalClockView
      },
      {
        path: 'colorClipboard',
        name: 'colorClipboard',
        component: ColorClipboardView,
      },
      {
        path: 'waterMark',
        name: 'waterMark',
        component: WaterMarkView,
      },
    ]
  },

  //小游戏
  {
    path:'/games',
    name:'games',
    component: GamesView
  },

  // 壁纸
  {
    path:'/wallpaper',
    name:'wallpaper',
    component: WallPaperView
  },

  //  微小说
  {
    path:'/novel',
    name:'novel',
    component: NovelView
  },

  //关于我
  {
    path:'/aboutme',
    name:'aboutme',
    component: AboutMeView
  },


  // 精品壁纸
  {
    path: '/bestwallpaper',
    name: 'bestwallpaper',
    children: [
      {
        path: 'greenEyesCat',
        name: 'greenEyesCat',
        component: GreenEyesCatView
      }
    ]
  },

  //小玩意儿
  {
    path: '/gadget',
    name: 'gadget',
    children: [
      {
        path: 'articleDetails',
        name: 'articleDetails',
        component: ArticleDetailsView,
      },
      {
        path:'personInfo',
        name:'personInfo',
        component: PersonInfoView
      }
    ]
  },

  //  小游戏
  {
    path: '/gamesdetail',
    name: 'gamesdetail',
    children: [
      {
        path: 'tictactoe',
        name: 'tictactoe',
        component: TictactoeView
      }
    ]
  },



  {
    path: '/practicalTable',
    name: 'practicalTable',
    component: PracticalTableView,
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
