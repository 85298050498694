<template>
	<div class="ticactoe">
		井字棋
	</div>
</template>

<script>
export default {
	name: "TictactoeView"
}
</script>

<style scoped lang="scss">



</style>