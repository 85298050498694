import axios from 'axios'
import config from './config'

// 接口地址
axios.defaults.baseURL = config[process.env.NODE_ENV].baseUrl
// 超时时间
axios.defaults.timeout = 10 * 1000
// 携带 cookie
// axios.defaults.withCredentials = true
// 请求头,headers 信息
// axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
// 请求拦截器,请求前进行操作
axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
// 请求拦截器,内部根据返回值,重新组装,统一管理
axios.interceptors.response.use(res => {
    if (typeof res.data !== 'object') {
      console.error('服务端异常！')
      return Promise.reject(res)
    }
    if (res.data.code != 200) {
      if (res.data.message) console.error(res.data.message)
      return Promise.reject(res.data)
    }
    return res.data
  })
  
  export default axios