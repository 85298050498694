<template>
	<div class="games-info">
		<div class="games-profile" id="profile">
			<h1>咕嘟小游戏</h1>
			<div class="btn">
				<a>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					一些好玩的小游戏
				</a>
			</div>
		</div>
		<div class="my-portfolio" id="portfolio">
			<div class="portfolio-cards">
				<div class=" portfolio-card" v-for="item in cards" :key="item.id">
					<img :src="item.imageUrl" class="portfolio-img">
					<h4>{{item.content}}</h4>
				</div>
			</div>
		</div>

	</div>

</template>

<script>
export default {
	data(){
		return{
			cards:[
				{id:1,imageUrl:'http://p1.music.126.net/wGbO39sAMI9lroiLYXJsDQ==/109951167760004737.jpg?param=120y120',content:'启示录'},
				{id:2,imageUrl:'http://p2.music.126.net/KTo5oSxH3CPA5PBTeFKDyA==/109951164581432409.jpg?param=120y120',content:'摩天动物园'},
				{id:3,imageUrl:'http://p2.music.126.net/fI5OPx5-0G4g384rGNQftA==/109951163751293472.jpg?param=120y120',content:'睡皇后'},
				{id:4,imageUrl:'http://p2.music.126.net/X7f92tSJ-b0_sC1u9tgAyQ==/109951163654227442.jpg?param=120y120',content:'毒苹果'},
				{id:5,imageUrl:'http://p2.music.126.net/tXCIFsVDK6IKcQ9YWxwOEg==/109951163523944497.jpg?param=120y120',content:'另一个童话'},
				{id:6,imageUrl:'http://p1.music.126.net/eX7aCeGTF3Lt_swJSbE4yQ==/18700493765616158.jpg?param=120y120',content:'25 LOOKS'},
				{id:7,imageUrl:'http://p2.music.126.net/kVwk6b8Qdya8oDyGDcyAVA==/1364493930777368.jpg?param=120y120',content:'新的心跳'},
				{id:8,imageUrl:'http://p2.music.126.net/w0EbVeyPeMZYQsaHV7PaAA==/109951163789161236.jpg?param=120y120',content:'The Best of G.E.M.'},
				{id:9,imageUrl:'http://p2.music.126.net/3XEICRFzQPrHPUMD0xm1Jw==/109951163069323980.jpg?param=120y120',content:'Xposed'},
				{id:10,imageUrl:'http://p1.music.126.net/Q6ROCCNP2qtqmDzp4uswgA==/109951163069327659.jpg?param=120y120',content:'My Secret'},
				{id:11,imageUrl:'http://p1.music.126.net/ZOspX8Q2sEjcF_wTHSWaqQ==/5853799906405628.jpg?param=120y120',content:'18'},
				{id:12,imageUrl:'http://p1.music.126.net/SRSzJNkVegFlCJVc49VvxQ==/109951163069324876.jpg?param=120y120',content:'G.E.M.'},
			],
			
		}
	}
}
</script>

<style scoped lang="scss">

.games-info{
	width: 100%;
	overflow: auto;
	align-items: center;
	background-color: rgba(16 18 27 / 10%);
	padding: 20px 40px;
	color: #ffffff;	
	.games-profile{
		width: 100%;
		height: 25%;
		flex-direction: column;
		display: flex;
		background: url("https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fshihuo.hupucdn.com%2Fucditor%2F20180622%2F4703x3135_4da264789c9a610887d7287e8750b321.jpeg&refer=http%3A%2F%2Fshihuo.hupucdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1662987630&t=bdf5fbedc140969146d30c3f741d7604");
		background-size: 100% 100%;
		background-color: #464646;
		//background-size: cover;
		align-items: center;
		justify-content: center;
		background-attachment: fixed;

		h1{
			font-size: 1.9em;
			text-shadow: 0 2px 1px black;
			margin-top: 0;
			
			span {
				font-family: Montez;
				font-size: 1.9em;
				font-weight: 300;
				color: #ff0080;
				margin: 0 10px;
				
			}
		}

		.btn{
			display: flex;
			flex-direction: row;
			align-items: center;
			a{
				position: relative;
				display: inline-block;
				padding: 10px;
				color: #03e9f4;
				text-decoration: none;
				//text-transform: uppercase;
				transition: 0.5s;
				letter-spacing: 2px;
				overflow: hidden;
				margin: 10px;
				
				
				span{
					position: absolute;
					display: block;
				}
				// 上
				span:nth-child(1){
					top: 0;
					left: 0;
					width: 100%;
					height: 2px;
					background: linear-gradient(90deg,transparent,#03e9f4);
					animation: animate1 1s linear infinite;
					
					@keyframes animate1{
						0%{
							left: -100%;
						}
						50%,100%{
							left: 100%;
						}
					}
				}
				// 右
				span:nth-child(2){
					top: -100%;
					right: 0;
					width: 2px;
					height: 100%;
					background: linear-gradient(180deg,transparent,#03e9f4);
					animation: animate2 1s linear infinite;
					animation-delay: 0.25s;
					
					@keyframes animate2{
						0%{
							top: -100%;
						}
						50%,100%{
							top: 100%;
						}
					}
				}
				// 下
				span:nth-child(3){
					bottom: 0;
					right: 0;
					width: 100%;
					height: 2px;
					background: linear-gradient(270deg,transparent,#03e9f4);
					animation: animate3 1s linear infinite;
					animation-delay: 0.50s;
					
					@keyframes animate3{
						0%{
							right: -100%;
						}
						50%,100%{
							right: 100%;
						}
					}
				}
				// 左
				span:nth-child(4){
					bottom: -100%;
					left: 0;
					width: 2px;
					height: 100%;
					background: linear-gradient(360deg,transparent,#03e9f4);
					animation: animate4 1s linear infinite;
					animation-delay: 0.75s;
					
					@keyframes animate4{
						0%{
							bottom: -100%;
						}
						50%,100%{
							bottom: 100%;
						}
					}
				}
			}
			
			a:hover{
				background: #03e9f4;
				color: #ffffff;
				box-shadow: 0 0 5px #03e9f4,
				0 0 25px #03e9f4,
				0 0 50px #03e9f4,
				0 0 200px #03e9f4;
				-webkit-box-reflect:below 1px linear-gradient(transparent, #0005);
			}
			
			//a:nth-child(1){
            //    filter: hue-rotate(100deg);
            //}
		}
		
		.profile-picture{
			width: 144px;
			height: 144px;
			border-radius: 50%;
			margin: 0 auto;
			position: relative;
			z-index: 2;
		}
		
		.display-name{
			color: #fff;
			position: relative;
			z-index: 2;
			text-align: center;
			
			h1,h4,h5{
				margin: 5px;
			}
			
		}
	}

	.my-portfolio{
		width: 100%;
		overflow: hidden;
		
		.portfolio-cards{
			display: flex;
			flex-wrap: wrap;
			margin-right: -10px;
			
			.portfolio-card{
				width: calc(20% - 10px);
				margin-right: 10px;
				
				margin-top: 10px;
				box-shadow: 0 2px 5px #888888;
				border-radius: 10px;
				background-color: white;
				
				.portfolio-img{
					width: 100%;
					border-radius: 10px 10px 0 0;
				}
				
				h4 {
					background: #fff;
					color: #000;
					text-align: center;
					margin: 0;
					padding: 4px 0 10px;
					border-radius: 0 0 10px 10px;
				}
				
				@media screen and (max-width: 1110px) {
					width: calc(50% - 10px);
				}
				@media screen and (max-width: 565px) {
					width: calc(100% - 10px);
				}
			}
			.portfolio-card:hover{
				cursor: pointer;
				transform: scale(1.02);
				border-radius: 10px;
				background-color: rgba(16 18 27 / 20%);
			}
			
		}
	}
	
}


</style>