<template>
	<div class="card-box">
		<div class="card" :class="active(2) || active(3)" >
			<div class="card-header">
				<img class="card-avatar" :src="imageUrl" alt="avatar"/>
				<h1 class="card-fullname">咕嘟咕嘟咕</h1>
				<h2 class="card-jobtitle">我很有文化</h2>
			</div>
			
			<div class="card-main">
				
				<!--第一页-->
				<div class="card-section" :class="active(1)">
					<div class="card-content">
						<div class="card-subtitle">关于咕嘟</div>
						<p class="card-desc">一些好玩的游戏,一些好看的壁纸,一些有用的工具</p>
					</div>
				</div>
				
				<!--第二页-->
				<div class="card-section" :class="active(2)">
					<div class="card-content">
						<div class="card-subtitle">历程</div>
						
						<div class="card-timeline">
							<div class="card-item" :data-year="item.time"  v-for="item in timeline" :key="item.id">
								<div class="card-item-title">{{item.title}}</div>
								<div class="card-item-desc">{{item.description}}</div>
							</div>
						</div>
					</div>
				</div>
				
				<!--第三页-->
				<div class="card-section" :class="active(3)">
					<div class="card-content">
						<div class="card-subtitle">联系</div>
						<div class="card-contact-wrapper">
							<div class="card-contact" v-for="item in contact" :key="item.id">
								<img :src="item.image">
								<span>{{item.info}}</span>
							</div>
							<button class="contact-me">一起工作</button>
						</div>
					</div>
				</div>
				
				<!--底部按钮-->
				<div class="card-buttons">
					<button :class="active(1)" @click="selectTab(1)">关于</button>
					<button :class="active(2)" @click="selectTab(2)">经历</button>
					<button :class="active(3)" @click="selectTab(3)">联系</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			index: 1,
			imageUrl:'image/IM.jpeg',
			timeline:[
				{time:'2023', title:'咕嘟上线啦', description:'一些好玩的游戏'},
			],
			
			contact:[
				{image:require('../assets/images/change_skin.png'), info:'中国北京市海淀区牡丹园'},
				{image: require('../assets/images/change_skin.png'), info: 'kkatnk@163.com'}
			]
		}
	},
	methods:{
		selectTab(index){
			this.index = index;
		},
		active(index){
			return index === this.index ? 'is-active' : ''
		}
	},
	computed:{
	
	}
}
</script>

<style lang="scss" scoped>

@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500|Jost:400,500,600&display=swap");


.card-box {
	font-family: "Jost", sans-serif;
	display: flex;
	flex-direction: column;
	//flex-wrap: wrap;
	background-color: rgba(16 18 27 / 10%);
	align-items: center;
	overflow: auto;
	width: 100%;
	
}

.card {
	width: 340px;
	background-color: rgba(255, 255, 255, 1);
	display: flex;
	flex-direction: column;
	transition: 0.5s;
	border-radius: 10px;
	box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.2);
	margin: 60px 0;
}

.card-header {
	position: relative;
	display: flex;
	height: 200px;
	flex-shrink: 0;
	width: 100%;
	transition: 0.5s;
	
	*{
		transition: 0.5s;
	}
	.card-avatar {
		width: 100px;
		height: 100px;
		box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
		border-radius: 50%;
		object-position: center;
		object-fit: cover;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%) translateY(-64px);
	}
	.card-fullname {
		position: absolute;
		bottom: 0;
		font-size: 22px;
		font-weight: 700;
		text-align: center;
		white-space: nowrap;
		transform: translateY(-10px) translateX(-50%);
		left: 50%;
	}
	
	.card-jobtitle {
		position: absolute;
		bottom: 0;
		font-size: 11px;
		white-space: nowrap;
		font-weight: 500;
		opacity: 0.7;
		text-transform: uppercase;
		letter-spacing: 1.5px;
		margin: 0;
		left: 50%;
		transform: translateX(-50%) translateY(-7px);
	}
}

.card.is-active {
	.card-header {
		height: 80px;
	}
	
	.card-avatar {
		transform: none;
		left: 20px;
		width: 50px;
		height: 50px;
		bottom: 10px;
	}
	
	.card-fullname,
	.card-jobtitle {
		left: 86px;
		transform: none;
	}
	
	.card-fullname {
		bottom: 18px;
		font-size: 19px;
	}
	
	.card-jobtitle {
		bottom: 16px;
		letter-spacing: 1px;
		font-size: 10px;
	}
}

.card-main {
	position: relative;
	flex: 1;
	display: flex;
	align-items: center;
	padding-top: 10px;
	flex-direction: column;
	
	.card-section {
		display: none;
		
		&.is-active {
			display: block;
			animation: fadeIn 0.6s both;
		}
		
		.card-content {
			padding: 20px;
			
			.card-subtitle {
				font-weight: 700;
				font-size: 13px;
				margin-bottom: 8px;
			}
			
			//第一块
			.card-desc {
				line-height: 1.6;
				color: #636b6f;
				font-size: 14px;
				margin: 0;
				font-weight: 400;
				font-family: "DM Sans", sans-serif;
			}
			
			//第二块
			.card-timeline {
				margin-top: 30px;
				position: relative;
				
				&:after {
					background: linear-gradient(
							to top,
							rgba(134, 214, 243, 0) 0%,
							rgba(81, 106, 204, 1) 100%
					);
					content: "";
					left: 42px;
					width: 2px;
					top: 0;
					height: 100%;
					position: absolute;
				}
				
				.card-item {
					position: relative;
					padding-left: 60px;
					padding-right: 20px;
					padding-bottom: 30px;
					z-index: 1;
					
					&:last-child {
						padding-bottom: 5px;
					}
					
					&:after {
						content: attr(data-year);
						width: 10px;
						position: absolute;
						top: 0;
						left: 37px;
						width: 8px;
						height: 8px;
						line-height: 0.6;
						border: 2px solid #fff;
						font-size: 11px;
						text-indent: -35px;
						border-radius: 50%;
						color: rgba(#868686, 0.7);
						background: linear-gradient(
								to bottom,
								lighten(#516acc, 20%) 0%,
								#516acc 100%
						);
					}
					
					.card-item-title {
						font-weight: 500;
						font-size: 14px;
						margin-bottom: 5px;
					}
					.card-item-desc {
						font-size: 13px;
						color: #6f6f7b;
						line-height: 1.5;
						font-family: "DM Sans", sans-serif;
					}
				}
				
			}
			
			//第三块
			.card-contact-wrapper {
				margin-top: 20px;
				
				.card-contact {
					display: flex;
					align-items: center;
					font-size: 13px;
					color: #6f6f7b;
					font-family: "DM Sans", sans-serif;
					line-height: 1.6;
					cursor: pointer;
					margin-bottom: 10px;
					
					& + & {
						margin-top: 16px;
					}
					
					img {
						flex-shrink: 0;
						width: 25px;
						height: 25px;
						transition: 0.3s;
						margin-right: 10px;
					}
					span{
						border-left: 1px solid #dfe2ec;
						padding-left: 12px;
					}
				}
				.contact-me {
					border: 0;
					outline: none;
					background: linear-gradient(
							to right,
							rgba(83, 200, 239, 0.8) 0%,
							rgba(81, 106, 204, 0.8) 96%
					);
					box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
					color: #fff;
					padding: 12px 16px;
					width: 100%;
					border-radius: 5px;
					margin-top: 25px;
					cursor: pointer;
					font-size: 14px;
					font-weight: 500;
					font-family: "Jost", sans-serif;
					transition: 0.5s;
				}
			}
			
		}
		
		//第一块
		.card-social {
			display: flex;
			align-items: center;
			padding: 0 20px;
			margin-bottom: 30px;
			
			svg {
				fill: rgb(165, 181, 206);
				width: 22px;
				display: block;
				transition: 0.5s;
			}
			
			a {
				color: #8797a1;
				height: 32px;
				width: 32px;
				border-radius: 50%;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				transition: 0.5s;
				background-color: rgba(93, 133, 193, 0.05);
				margin-right: 10px;
				
				&:hover {
					svg {
						fill: darken(rgb(165, 181, 206), 20%);
					}
				}
				
				&:last-child {
					margin-right: 0;
				}
			}
			
			
		}
	}
	
	//底部按钮
	.card-buttons {
		display: flex;
		background-color: #fff;
		margin-top: auto;
		width: 98%;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		
		button {
			flex: 1 1 auto;
			user-select: none;
			background: 0;
			font-size: 13px;
			border: 0;
			padding: 15px 5px;
			cursor: pointer;
			color: #5c5c6d;
			transition: 0.5s;
			font-family: "Jost", sans-serif;
			font-weight: 500;
			outline: 0;
			border-bottom: 3px solid transparent;
			
			&.is-active,
			&:hover {
				color: #2b2c48;
				border-bottom: 3px solid #8a84ff;
				background: linear-gradient(
						to bottom,
						rgba(127, 199, 231, 0) 0%,
						rgba(207, 204, 255, 0.2) 44%,
						rgba(211, 226, 255, 0.4) 100%
				);
			}
		}
	}
}


</style>